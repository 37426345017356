import { MarkerClustererProps } from '@react-google-maps/api';

export const clusterStyles: MarkerClustererProps['styles'] = [
  {
    url: '/assets/marker-clusterer/marker.svg',
    width: 48,
    height: 48,
    textSize: 16,
    textColor: 'white',
    className: '[&_img]:h-12',
  },
  {
    url: '/assets/marker-clusterer/marker.svg',
    width: 48,
    height: 48,
    textSize: 16,
    textColor: 'white',
    className: '[&_img]:h-12',
  },
  {
    url: '/assets/marker-clusterer/marker.svg',
    width: 48,
    height: 48,
    textSize: 16,
    textColor: 'white',
    className: '[&_img]:h-12',
  },
  {
    url: '/assets/marker-clusterer/marker.svg',
    width: 48,
    height: 48,
    textSize: 16,
    textColor: 'white',
    className: '[&_img]:h-12',
  },
  {
    url: '/assets/marker-clusterer/marker.svg',
    width: 48,
    height: 48,
    textSize: 16,
    textColor: 'white',
    className: '[&_img]:h-12',
  },
];

export const SERVICE_TYPES = ['all', 'buy', 'buy-and-sell'] as const;
