import { useMemo } from 'react';

import { getDefaultLocation } from '@/utils';
import { BrowserLocation } from '@/utils/types';
import { useLang } from '@/hooks/useLang';

export const useInitialMapCenter = (
  defaultLocation: BrowserLocation | undefined,
  isMapLoaded: boolean,
): google.maps.LatLng | undefined => {
  const lang = useLang();
  const coords = defaultLocation || getDefaultLocation(lang);

  return useMemo(() => {
    if (!isMapLoaded) {
      return undefined;
    }

    return new google.maps.LatLng({
      lat: coords.latitude,
      lng: coords.longitude,
    });
  }, [coords.latitude, coords.longitude, isMapLoaded]);
};
