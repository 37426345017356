import { MAX_MONEY_AMOUNT, MIN_MONEY_AMOUNT } from '../../organisms/CalculatorWithButton/consts';

export const clampValue = (value: number, minValue?: number, maxValue?: number) => {
  if (value < (minValue ?? MIN_MONEY_AMOUNT) || !value) {
    return minValue ?? MIN_MONEY_AMOUNT;
  }

  if (value > (maxValue ?? MAX_MONEY_AMOUNT)) {
    return maxValue ?? MAX_MONEY_AMOUNT;
  }

  return value;
};
