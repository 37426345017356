import { getCurrencyFromLang } from '@/app/[lang]/pre-sell/partials/PreSellForm/partials/Steps/ScanQrCodeStep/hooks';
import { Lang } from '@/types/locales';
import { formatNumber } from '@/utils';

export const makeFinalAmountValidator =
  (validAmounts: number[], locale: Lang) => (value: unknown) => {
    if (typeof value !== 'number') {
      return 'Invalid amount';
    }

    if (validAmounts.includes(value)) {
      return true;
    }

    const nearestLowerAmountIndex = Math.max(
      0,
      validAmounts.findLastIndex((validAmount) => validAmount <= value),
    );
    const nearestHigherAmountIndex = Math.max(
      0,
      Math.min(nearestLowerAmountIndex + 1, validAmounts.length - 1),
    );

    const nearestLowerAmount = validAmounts[nearestLowerAmountIndex] || 0;
    const nearestHigherAmount = validAmounts[nearestHigherAmountIndex] || 0;

    const currency = getCurrencyFromLang(locale);

    const formattedNearestLowerAmount = formatNumber(nearestLowerAmount, {
      locale,
      currency,
    });
    const formattedNearestHigherAmount = formatNumber(nearestHigherAmount, {
      locale,
      currency,
    });

    const hint =
      nearestLowerAmountIndex === nearestHigherAmountIndex
        ? `The maximum you can withdraw is ${formattedNearestLowerAmount}.`
        : `You can withdraw ${formattedNearestLowerAmount} or ${formattedNearestHigherAmount}.`;

    return `The ATM doesn't have enough bills to withdraw that amount. ${hint}`;
  };
