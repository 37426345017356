import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import { PrismicSerializer } from '@/utils/prismic/serializer';
const RichText = ({
  components = {},
  ...props
}: PrismicRichTextProps) => {
  return <PrismicRichText components={{
    ...PrismicSerializer,
    ...components
  }} {...props} data-sentry-element="PrismicRichText" data-sentry-component="RichText" data-sentry-source-file="index.tsx" />;
};
export default RichText;