import { useController } from 'react-hook-form';
import clsx from 'clsx';
import { forwardRef } from 'react';
import type { FormInputProps, InputProps } from './types';
const Input = forwardRef<HTMLInputElement, InputProps>(({
  label,
  placeholder,
  required,
  className,
  error,
  ...rest
}, ref) => {
  const isErrorString = typeof error === 'string';
  return <div className={clsx('flex w-full flex-col', className)}>
        {label && <label className="mb-3 text-body-8 font-semibold text-gray-600" htmlFor={rest.id}>
            {label}
            <span className="text-error-300">{required ? '*' : ''}</span>
          </label>}
        <input className={clsx('w-full rounded-[.25rem] border p-3 text-body-8 placeholder:text-body-8 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none', {
      'border-error-300': !!error,
      'border-gray-200': !error
    })} placeholder={placeholder} required={required} ref={ref} {...rest} />
        {error && isErrorString ? <p className="ml-auto mt-1 text-body-8 text-error-300">{error}</p> : error}
      </div>;
});
Input.displayName = 'Input';
export const FormInput = ({
  name,
  error: errorProp,
  ...rest
}: FormInputProps) => {
  const {
    field,
    fieldState: {
      error
    }
  } = useController({
    name
  });
  return <Input {...field} {...rest} error={errorProp ? errorProp : error?.message} data-sentry-element="Input" data-sentry-component="FormInput" data-sentry-source-file="index.tsx" />;
};
export default Input;