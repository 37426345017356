import useSWR, { SWRConfiguration } from 'swr';
import { ROUTES } from '../routes';
import { useLang } from './useLang';
import { getUserLocation } from '../utils/swrRequests';

const useUserLocation = ({
  enabled = true,
  ...config
}: {
  enabled?: boolean;
} & SWRConfiguration<Awaited<ReturnType<typeof getUserLocation>>, unknown> = {}) => {
  const lang = useLang();

  return useSWR(
    enabled ? [ROUTES.APIUserLocation, lang] : null,
    async () => await getUserLocation(lang),
    {
      revalidateOnFocus: false,
      ...config,
    },
  );
};

export default useUserLocation;
