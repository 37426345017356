import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const useRecaptcha = () => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleRecaptchaSubmission = (token: string | null) => {
    if (!token) {
      setRecaptchaToken(null);
      return;
    }
    setRecaptchaToken(token);
  };

  const handleRecaptchaChange = (token: string | null) => {
    handleRecaptchaSubmission(token);
  };

  const handleRecaptchaExpired = () => {
    setRecaptchaToken(null);
  };

  const resetRecaptcha = () => {
    recaptchaRef.current?.reset();
    setRecaptchaToken(null);
  };

  return {
    recaptchaRef,
    recaptchaToken,
    handleRecaptchaChange,
    handleRecaptchaExpired,
    resetRecaptcha,
  };
};

export default useRecaptcha;
