'use client';

import useSWR from 'swr';

import { ROUTES } from '@/routes';
import { makeRequest } from '@/utils';
import { roundNumber } from '@/utils/currency';
import { CryptoPrices } from '@/types/location';
import { CryptoCurrency } from '@/consts/api';

import { ExchangeRateResponse } from './types';

export const useETransferCryptoAmount = (
  amount: number,
  currency: CryptoCurrency = CryptoCurrency.BTC,
  prices?: Omit<CryptoPrices, 'timestamp'>,
) => {
  const { data, isLoading } = useSWR(
    ROUTES.eTransferExchangeRate,
    async () => makeRequest<ExchangeRateResponse>(ROUTES.eTransferExchangeRate),
    { isPaused: () => !!prices },
  );

  if (!amount) {
    return {
      amount: roundNumber(0, 8),
      isLoading,
    };
  }

  if (prices) {
    const priceKey = `${currency}SellPrice`;
    const price = prices[priceKey] ?? 1;

    return {
      amount: roundNumber(amount * (1 / price), 8),
      isLoading,
    };
  }

  const { BTC = undefined } = data || {};

  if (!BTC) {
    return { amount: roundNumber(0, 8), isLoading };
  }

  return {
    amount: roundNumber(amount / BTC, 8),
    isLoading,
  };
};
